@font-face {
  font-family: Press Gothic;
  font-weight: 400;
  src: url(/fonts/PressGothicRegular.ttf);
}

@font-face {
  font-family: ArialNarrow;
  src: url(/fonts/ArialNarrow.ttf);
}

@font-face {
  font-family: MontserratAlternates;
  src: url(/fonts/MontserratAlternates-SemiBold.ttf);
}

html, body {
  margin: 0;
  background: black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /* hack to prevent backgorund color change */
  transition: background-color 50000s ease-in-out 0s;
}
